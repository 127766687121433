/* eslint-disable @typescript-eslint/no-unused-vars */
import { cn } from "@/lib/utils";
import HeroSectionChildren from "@/wireframes/page-sections/hero/HeroSectionChildren";
import { Button } from "@/components/ui/button";
import { ChangeEvent, FormEvent, useState } from "react";
import DialogComponent from "@/components/dialog/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { isEmailValid } from "@/utilities/formValidation";
import { toast } from "@/toast/use-toast";
import useWaitlistBackend from "@/api/firebase/core/waitlist/useWaitlistBackend";
import ContactUsForm from "../contact-us/contact-us-form";
import WhishlistForm from "./whishlist-form";
import BlurIn from "@/wireframes/text-animations/blur-in";

// interface FormData {
//   emailId: string;
// }

// interface ValidationData {
//   emailValidation: { isValid: boolean; message: string };
// }

const WaitListSection: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);

  // const [formData, setFormData] = useState<FormData>({
  //   emailId: "",
  // });

  // const [validationData, setValidationData] = useState<ValidationData>({
  //   emailValidation: { isValid: true, message: "" },
  // });

  // const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = event.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  // const { createWaitlist } = useWaitlistBackend();

  // const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();

  //   try {
  //     const { emailId } = formData;
  //     const emailValidation = isEmailValid(emailId);

  //     setValidationData({
  //       emailValidation,
  //     });

  //     if (emailValidation.isValid) {
  //       const userWaitlist = await createWaitlist(formData);

  //       // Reset form data
  //       setFormData({
  //         emailId: "",
  //       });

  //       toast({
  //         title: "Form Submitted",
  //         description: "Your email ID has been successfully submitted.",
  //       });

  //       // Optionally, redirect or perform other actions
  //       // window.location.href = "";
  //     }
  //   } catch (error) {
  //     console.error("Error adding document: ", error);
  //     toast({
  //       title: "Submission Error",
  //       description: "There was an error submitting your email ID. Please try again.",
  //       variant: "destructive",
  //     });
  //   }
  // };

  const UserVerify = () => {
    return (
      <>
      <WhishlistForm />
      </>
      // <form
      //   className={cn("grid items-start gap-4")}
      //   method="post"
      //   onSubmit={handleSubmit}
      // >
      //   <div className="grid gap-2">
      //     <Label htmlFor="emailId" className="text-accent font-bold">
      //       Email<span className="text-red-500 text-xl"> *</span>
      //     </Label>
      //     <Input
      //       id="emailId"
      //       name="emailId"
      //       type="email"
      //       className="border border-accent rounded"
      //       placeholder="Enter email ID"
      //       value={formData.emailId}
      //       onChange={handleChange}
      //     />
      //     {!validationData.emailValidation.isValid && (
      //       <p className="text-red-500 text-start text-sm">
      //         {validationData.emailValidation.message}
      //       </p>
      //     )}
      //   </div>
      //   <div className="text-end">
      //     <Button
      //       className="bg-accent w-auto rounded-xl hover:bg-secondary hover:text-accent mt-4"
      //       type="submit"
      //     >
      //       Submit
      //     </Button>
      //   </div>
      // </form>
    );
  };

  const handleButtonClick = () => {
    setOpenDialog(true);
  };

  const WaitList = () => {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="container mx-auto px-4 py-4 mb-10">
        <header className="text-center md:text-start mb-5  sm:my-10 text-wrap">
          <BlurIn
            word={`Join the Future of Language Learning with Vani`}
            className="text-1xl text-center md:text-start font-bold text-accent"
          />
        </header>
          <div className="flex flex-col justify-start md:justify-center lg:flex-row">
            <div className="text-sm md:text-lg text-center md:text-center text-balance max-w-full flex justify-center items-center px-4">
              <div>
                <div>
                  <h4 className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl leading-normal font-bold text-accent mt-10 mb-8">
                    Join the Waitlist
                  </h4>
                </div>
                <h5 className="text-lg md:text-1xl lg:text-xl font-normal mb-4 text-black">
                  Are you ready to start your language learning journey?
                </h5>
                <p className="text-lg md:text-1xl lg:text-xl font-normal mb-4 text-black">
                  And to get better results with "Vani" in 2024?
                </p>
                <div>
                  <Button
                    className="bg-accent px-3 py-5 text-secondary hover:bg-secondary hover:text-accent rounded-full text-sm md:text-lg"
                    onClick={handleButtonClick}
                  >
                    Join the Waitlist
                  </Button>
                </div>
                <DialogComponent
                  dialogTitle={"Join the Waitlist"}
                  open={openDialog}
                  setOpen={setOpenDialog}
                  children={<UserVerify />}
                  classNameDialogContent={"text-black border border-accent"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <HeroSectionChildren
      className="flex items-start min-h-0"
      id="wait-list"
      children={<WaitList />}
    />
  );
};

export default WaitListSection;
