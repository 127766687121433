/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, FormEvent, useState } from "react";
import { useTheme } from "@/wireframes/theme/theme-provider";

import { useTranslation } from "react-i18next";
import ReCAPTCHAComponent from "@/components/header/ReCAPTCHAComponent";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import HeroSectionChildren from "@/wireframes/page-sections/hero/HeroSectionChildren";
import GradualSpacing from "@/wireframes/text-animations/gradual-spacing";

import useContactUsBackend from "@/api/firebase/core/contact-us/useContactUsBackend";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { toast } from "@/toast/use-toast";
import { isContactNumberValid, isDescriptionValid, isEmailValid, isNameValid } from "@/utilities/formValidation";
import useWaitlistBackend from "@/api/firebase/core/waitlist/useWaitlistBackend";

interface FormData {
  emailId: string;
}

interface ValidationData {
  emailValidation: { isValid: boolean; message: string };
}

const WhishlistForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    emailId: "",
  });

  const [validationData, setValidationData] = useState<ValidationData>({
    emailValidation: { isValid: true, message: "" },
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { createWaitlist } = useWaitlistBackend();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const { emailId } = formData;
      const emailValidation = isEmailValid(emailId);

      setValidationData({
        emailValidation,
      });

      if (emailValidation.isValid) {
        const userWaitlist = await createWaitlist(formData);

        // Reset form data
        setFormData({
          emailId: "",
        });

        toast({
          title: "Form Submitted",
          description: "Your email ID has been successfully submitted.",
        });

        // Optionally, redirect or perform other actions
        // window.location.href = "";
      }
    } catch (error) {
      console.error("Error adding document: ", error);
      toast({
        title: "Submission Error",
        description: "There was an error submitting your email ID. Please try again.",
        variant: "destructive",
      });
    }
  };
  return (
     
            <div className="w-full ">
              <form
                className={`grid items-start gap-4 `}
                method="post"
                onSubmit={handleSubmit}
              >
                                 
                  <div className="mb-4">
                    <label
                      className={`block text-start text-sm font-bold mb-2`}
                      htmlFor="email"
                    >
                      Email<span className="text-red-400 text-xl"> *</span>
                    </label>
                    <Input
                      className="border border-accent rounded"
                      id="emailId"
                      name="emailId"
                      type="email"
                      placeholder="Email"
                      value={formData.emailId}
                      onChange={handleChange}
                    />
                    {!validationData.emailValidation.isValid && (
                      <p className="text-red-400 text-start text-sm">
                        {validationData.emailValidation.message}
                      </p>
                    )}
                  </div>

                <div className={`z-10 flex items-center justify-center`}>
                <Button
                    type="submit"
                    className="mt-4 bg-accent text-secondary hover:bg-secondary hover:text-accent rounded-full text-lg"
                  >
                    SUBMIT
                  </Button>
                </div>
              </form>
            </div>
    
  );
};

export default WhishlistForm;
