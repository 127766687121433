import React from "react";
import DefaultLayout from "@/wireframes/layouts/default";
import Header from "@/components/header/Header";
import Footer from "@/components/header/Footer";
import Teams from "@/page-sections/teams/teams";
import TeamsOne from "./teams/teams-one";
import TeamsTwo from "./teams/teams-two";

const TeamsPage: React.FC = () => {
  return (
    <>
      <DefaultLayout header={<Header />} footer={<Footer />}>
        <div className="section-colors">
          <Teams/>
        </div>
        <div className="section-colors">
          <TeamsOne/>
        </div>
        <div className="section-colors">
          <TeamsTwo/>
        </div>
      </DefaultLayout>
      
    </>
  );
};

export default TeamsPage;
