/* eslint-disable @typescript-eslint/no-unused-vars */
import { cn } from "@/lib/utils";
import HeroSectionChildren from "@/wireframes/page-sections/hero/HeroSectionChildren";
import { Button } from "@/components/ui/button";
import { ChangeEvent, FormEvent, useState } from "react";
import DialogComponent from "@/components/dialog/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { isEmailValid } from "@/utilities/formValidation";
import { toast } from "@/toast/use-toast";
import useWaitlistBackend from "@/api/firebase/core/waitlist/useWaitlistBackend";
import ContactUsForm from "../contact-us/contact-us-form";
import WhishlistForm from "./whishlist-form";
import BlurIn from "@/wireframes/text-animations/blur-in";

const VoteSection: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleButtonClick = () => {
    setOpenDialog(true);
  };

  const Vote = () => {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="container mx-auto px-4 py-4 mb-10">
        <header className="text-center md:text-start mb-5  sm:my-10 text-wrap">
          <BlurIn
            word={`Support Us` }
            className="text-1xl text-center md:text-start font-bold text-accent"
          />
        </header>
          <div className="flex flex-col justify-start md:justify-center lg:flex-row">
            <div className="text-sm md:text-lg text-center md:text-center text-balance max-w-full flex justify-center items-center px-4">
              <div>
                <div>
                  <h4 className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl leading-normal font-bold text-accent mt-10 mb-8">
                  Vote for Us in the Google Gemini AI Competition!
                  </h4>
                </div>
                <h5 className="text-lg md:text-1xl lg:text-xl font-normal mb-4 text-black">
                We’re thrilled to announce that Vani, your personalized AI-powered language learning platform, is competing in the prestigious Google Gemini AI Competition! <br/> Your vote can help us bring personalized, interactive language lessons to even more learners around the world.
                </h5>
                {/* <p className="text-lg md:text-1xl lg:text-xl font-normal mb-4 text-black">
                  And to get better results with "Vani" in 2024?
                </p> */}
                <div>
                  <a  href="https://ai.google.dev/competition/projects/vani">
                  <Button
                    className="bg-accent px-3 py-5 text-secondary hover:bg-secondary hover:text-accent rounded-full text-sm md:text-lg"
                   style={{width: "20%"}}
                  >
                    Vote
                  </Button>
                  </a>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <HeroSectionChildren
      className="flex items-start min-h-0"
      id="vote"
      children={<Vote />}
    />
  );
};

export default VoteSection;
