/* eslint-disable @typescript-eslint/no-unused-vars */
"use client";
import "@/styles/globals.css";
import "@/i18n/i18n";

import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

import HomePage from "@/page-sections/home-page";
import DefaultLayout from "@/wireframes/layouts/default";

import { getToken, onMessage } from "firebase/messaging";
import { useEffect } from "react";
import { messaging } from "@/firebase/firebaseConfig";
import BlankPage from "@/page-sections/blank-page";
import AboutUsPage from "@/page-sections/about-us-page";
import ContactUsPage from "@/page-sections/contact-us-page";
import PrivacyPage from "@/page-sections/privacy-page";
import Header from "@/components/header/Header";
import Footer from "@/components/header/Footer";
import Services from "@/page-sections/home/services";
import ServicePage from "@/page-sections/service-page";
import QrCodePage from "@/page-sections/qr-code-page";    
import BlogsPage from "@/page-sections/blogs-page";
import Blogs from "@/page-sections/home/blogs";
import ChatbotPage from "@/page-sections/chatbot-page";
import ChatbotFloatingBtn from "@/components/ui/chatbot/chatbotFloatingBtn";
// import MultiBlogs from "@/page-sections/blank/multi-blogs";
import MultiBlogsPage from "@/page-sections/multiblogs-page";
import { Toaster } from "@/toast/toaster";
import ComponentsPage from "@/page-sections/components-page";
import TeamsPage from "@/page-sections/teams-page";
import LandingPage from "@/page-sections/landing-page";
const { VITE_APP_VAPID_KEY } = import.meta.env;

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<LandingPage />}></Route>
      <Route path="/home" element={<HomePage />}></Route>
      <Route path="/contact-us" element={<ContactUsPage />}></Route>  
      <Route path="/about-us" element={<AboutUsPage />}></Route>
      <Route path="/:slug" element={<PrivacyPage />}></Route>
      <Route path="/services" element={<Services />} />
      <Route path="/services/:slug" element={<ServicePage/>} />
      <Route path="/blogs" element={<Blogs/>} />
      <Route path="/blogs/:id" element={<BlogsPage/>} />
      <Route path="/qr_code" element={<QrCodePage/>} />
      <Route path="/chatbot" element={<ChatbotPage/>} />
      <Route path="/multiblogs" element={<MultiBlogsPage/>} />
      <Route path="/components" element={<ComponentsPage/>} />
      <Route path="/teams" element={<TeamsPage/>} />
      <Route  
        path="/blank"
        element={
          <DefaultLayout header={<Header />} footer={<Footer />}>
            <>
              <BlankPage />
            </>
          </DefaultLayout>
        }
      ></Route>
    </>
  )
);

const App = () => {
  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: VITE_APP_VAPID_KEY,
      });

      //We can send token to server
      console.log("Token generated : ", token);
    } else if (permission === "denied") {
      //notifications are blocked
      alert("You denied for the notification");
    }
  }

  // open if you need to notification
  // useEffect(() => {
  //   requestPermission();
  // }, []);

  onMessage(messaging, (payload) => {
    console.log(" i am getting this message ");

    console.log(payload);
  });

  return (
    <>
      <RouterProvider router={router} />
      {/* <ChatbotFloatingBtn/> */}
      <Toaster />
      {/* <FloatingWhatsAppIcon /> */}
      
    </>
  );
};

export default App;
