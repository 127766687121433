import React from "react";
import DefaultLayout from "@/wireframes/layouts/default";
// import Chatbot from "./chatbot/chatbot";

const ChatbotPage: React.FC = () => {
  return (
    <>
      <DefaultLayout>
        <div className="section-colors">
          {/* <Chatbot /> */}
        </div>
      </DefaultLayout>
    </>
  );
};

export default ChatbotPage;
