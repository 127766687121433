"use client";

import React, { forwardRef, useRef } from "react";
import VaniGuruLogo from "@/assets/vani/logos/vani-01.png";
import GeminiLogo from "@/assets/google-gemini-icon.png";
import ServerLogo from "@/assets/cloud-server.png";
import UserLogo from "@/assets/user.png";
import { cn } from "@/lib/utils";
import { AnimatedBeam } from "@/wireframes/special-effects/animated-beam";
import HeroSectionChildren from "@/wireframes/page-sections/hero/HeroSectionChildren";
import BlurIn from "@/wireframes/text-animations/blur-in";
import Marquee from "@/wireframes/components/marquee";
import Card from "@/components/card/card";
import howItWorks from "@/datasets/how-it-works.json";

const Circle = forwardRef<
  HTMLDivElement,
  { className?: string; children?: React.ReactNode }
>(({ className, children }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "z-10 flex h-15 w-15 md:h-28 md:w-28 items-center justify-center rounded-full border-2 bg-white p-3 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]",
        className
      )}
    >
      {children}
    </div>
  );
});

export function AnimatedBeamDemo() {
  const containerRef = useRef<HTMLDivElement>(null);
  const div1Ref = useRef<HTMLDivElement>(null);
  const div2Ref = useRef<HTMLDivElement>(null);

  const div3Ref = useRef<HTMLDivElement>(null);

  const div4Ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className="relative flex w-full pt-1 pb-1 items-center justify-center overflow-hidden rounded-lg z-0"
      ref={containerRef}
    >
      <div className="flex h-full w-full flex-col items-stretch justify-between gap-10">
        <div className="flex flex-row justify-between">
          <Circle ref={div1Ref}>
            <img
              className="h-10 md:h-12 w-10 md:w-12"
              src={UserLogo}
              alt="vani"
            />
          </Circle>
          <Circle ref={div2Ref}>
            <img
              className="h-10 md:h-12 w-10 md:w-12"
              src={VaniGuruLogo}
              alt="vani"
            />
          </Circle>

          <Circle ref={div3Ref}>
            <img
              className="h-10 md:h-12 w-10 md:w-12"
              src={ServerLogo}
              alt="vani"
            />
          </Circle>

          <Circle ref={div4Ref}>
            <img
              className="h-10 md:h-12 w-10 md:w-12"
              src={GeminiLogo}
              alt="vani"
            />
          </Circle>
        </div>
      </div>

      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div1Ref}
        toRef={div2Ref}
        startYOffset={10}
        endYOffset={10}
        curvature={-20}
        delay={1}
      />

      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div2Ref}
        toRef={div3Ref}
        startYOffset={10}
        endYOffset={10}
        curvature={-20}
        delay={2}
      />

      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div3Ref}
        toRef={div4Ref}
        startYOffset={10}
        endYOffset={10}
        curvature={-20}
        delay={3}
      />

      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div3Ref}
        toRef={div4Ref}
        startYOffset={-10}
        endYOffset={-10}
        curvature={20}
        delay={4}
        reverse
      />

      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div2Ref}
        toRef={div3Ref}
        startYOffset={-10}
        endYOffset={-10}
        curvature={20}
        delay={5}
        reverse
      />

      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div1Ref}
        toRef={div2Ref}
        startYOffset={-10}
        endYOffset={-10}
        curvature={20}
        delay={6}
        reverse
      />
    </div>
  );
}

const HowItWorksSection: React.FC = () => {
  const Works = () => {
    return (
      <>
        <div className="w-full px-4">
          <header className="text-start mb-8 px-4 sm:my-10 text-wrap">
            <BlurIn
              word={`How It Works`}
              className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl leading-normal font-bold text-accent"
            />
          </header>
          <div className="flex flex-col justify-center items-center">
            <div className="w-full">
              <Marquee
                pauseOnHover
                className="[--duration:40s] flex items-center"
              >
                {howItWorks.map((data) => (
                  <Card className="w-full text-foreground border-secondary bg-background rounded-3xl min-w-80">
                    <div className="flex justify-center">
                      <div className="flex justify-center  w-24 h-24 items-center shadow-lg shadow-foreground">
                        <div className=" border border-secondary rounded-full ">
                          <img
                            src={data.img}
                            alt={data.title}
                            className="w-20 h-20 rounded-full shadow-lg shadow-cyan-500/50 object-cover"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 text-black">
                      <p className="text-2xl text-accent">{data.title}</p>
                      <p className="text-sm font-normal text-start mt-2">
                        {data.description}
                      </p>
                    </div>
                  </Card>
                ))}
              </Marquee>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center">
          <div className="container mx-auto px-4 py-4 mb-10">
            <div className="flex flex-col lg:flex-row">
              <AnimatedBeamDemo />
            </div>
          </div>
        </div>
      </>
    );
  };

  return <HeroSectionChildren id="how-it-works" children={<Works />} />;
};

export default HowItWorksSection;

// "use client";

// import React, { forwardRef, useRef } from "react";
// import VaniGuruLogo from "@/assets/vani/logos/vani-01.png";
// import GeminiLogo from "@/assets/google-gemini-icon.png";
// import ServerLogo from "@/assets/cloud-server.png";
// import UserLogo from "@/assets/user.png";
// import { cn } from "@/lib/utils";
// import { AnimatedBeam } from "@/wireframes/special-effects/animated-beam";
// import HeroSectionChildren from "@/wireframes/page-sections/hero/HeroSectionChildren";
// import BlurIn from "@/wireframes/text-animations/blur-in";

// const Circle = forwardRef<
//   HTMLDivElement,
//   { className?: string; children?: React.ReactNode }
// >(({ className, children }, ref) => {
//   return (
//     <div
//       ref={ref}
//       className={cn(
//         "z-0 flex items-center justify-center rounded-full border-2 bg-white p-3 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]",
//         "h-20 w-20 md:h-24 md:w-24 lg:h-28 lg:w-28", // Responsive height and width
//         className
//       )}
//     >
//       {children}
//     </div>
//   );
// });

// export function AnimatedBeamDemo() {
//   const containerRef = useRef<HTMLDivElement>(null);
//   const div1Ref = useRef<HTMLDivElement>(null);
//   const div2Ref = useRef<HTMLDivElement>(null);
//   const div3Ref = useRef<HTMLDivElement>(null);
//   const div4Ref = useRef<HTMLDivElement>(null);

//   return (
//     <div
//       className="relative flex w-full items-center justify-center overflow-hidden rounded-lg z-0"
//       ref={containerRef}
//     >
//       <div className="flex flex-col md:flex-row h-full w-full items-center justify-between gap-6 md:gap-10 lg:gap-12">
//         <Circle ref={div1Ref}>
//           <img className="h-10 w-auto md:h-12 lg:h-14" src={UserLogo} alt="vani" />
//         </Circle>
//         <Circle ref={div2Ref}>
//           <img className="h-10 w-auto md:h-12 lg:h-14" src={VaniGuruLogo} alt="vani" />
//         </Circle>
//         <Circle ref={div3Ref}>
//           <img className="h-10 w-auto md:h-12 lg:h-14" src={ServerLogo} alt="vani" />
//         </Circle>
//         <Circle ref={div4Ref}>
//           <img className="h-10 w-auto md:h-12 lg:h-14" src={GeminiLogo} alt="vani" />
//         </Circle>
//       </div>

//       <AnimatedBeam
//         containerRef={containerRef}
//         fromRef={div1Ref}
//         toRef={div2Ref}
//         startYOffset={10}
//         endYOffset={10}
//         curvature={-20}
//         delay={1}
//       />

//       <AnimatedBeam
//         containerRef={containerRef}
//         fromRef={div2Ref}
//         toRef={div3Ref}
//         startYOffset={10}
//         endYOffset={10}
//         curvature={-20}
//         delay={2}
//       />

//       <AnimatedBeam
//         containerRef={containerRef}
//         fromRef={div3Ref}
//         toRef={div4Ref}
//         startYOffset={10}
//         endYOffset={10}
//         curvature={-20}
//         delay={3}
//       />

//       <AnimatedBeam
//         containerRef={containerRef}
//         fromRef={div3Ref}
//         toRef={div4Ref}
//         startYOffset={-10}
//         endYOffset={-10}
//         curvature={20}
//         delay={4}
//         reverse
//       />

//       <AnimatedBeam
//         containerRef={containerRef}
//         fromRef={div2Ref}
//         toRef={div3Ref}
//         startYOffset={-10}
//         endYOffset={-10}
//         curvature={20}
//         delay={5}
//         reverse
//       />

//       <AnimatedBeam
//         containerRef={containerRef}
//         fromRef={div1Ref}
//         toRef={div2Ref}
//         startYOffset={-10}
//         endYOffset={-10}
//         curvature={20}
//         delay={6}
//         reverse
//       />
//     </div>
//   );
// }

// const HowItWorksSection: React.FC = () => {
//   const Works = () => {
//     return (
//       <div className="flex flex-col justify-center items-center">
//         <div className="container mx-auto px-4 py-4 mb-10">
//         <header className="text-center md:text-start mb-8 px-4 sm:my-10 text-wrap">
//             <BlurIn
//               word={`How It Works`}
//               className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl leading-normal font-bold text-accent"
//             />
//           </header>

//           <div className="flex flex-col items-center lg:flex-row">
//             <AnimatedBeamDemo />
//           </div>
//         </div>
//       </div>
//     );
//   };

//   return <HeroSectionChildren id="how-it-works" children={<Works />} />;
// };

// export default HowItWorksSection;
