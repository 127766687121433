import React from "react";
import DefaultLayout from "@/wireframes/layouts/default";
import Header from "@/components/header/Header";
import Footer from "@/components/header/Footer";
import BlogHeaderSection from "@/page-sections/blogs/blog-headerSection";
import BlogDetails from "@/page-sections/blogs/blog-details";

const BlogsPage: React.FC = () => {
  return (
    <>
      <DefaultLayout header={<Header />} footer={<Footer />}>
        <div className="section-colors">
          <BlogHeaderSection />
        </div>

        <div className="section-colors">
          <BlogDetails />
        </div>
      </DefaultLayout>
    </>
  );
};

export default BlogsPage;
