import React from "react";
import DefaultLayout from "@/wireframes/layouts/default";
import Header from "@/components/header/Header";
import Footer from "@/components/header/Footer";
import MultiBlogs from "@/page-sections/blogs/multiple-blogs/all-blogs/all-blogs";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import MultiBlogHeaderSection from "./blogs/multiple-blogs/header/multi-blogs-headerSection";
import MultiBlogsTabs from "./blogs/multiple-blogs/multiblogs-tabs";

const MultiBlogsPage: React.FC = () => {
  return (
    <>
      <DefaultLayout header={<Header />} footer={<Footer />}>
       

        <div className="section-colors">
          <MultiBlogHeaderSection/>
        </div>
        <div className="section-colors">
          <MultiBlogsTabs/>
        </div>
      </DefaultLayout>
    </>
  );
};

export default MultiBlogsPage;
