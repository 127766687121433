

export const emailErrorMessage = "Please provide a valid email address.";
export const emailIsRequiredErrorMessage = "Please enter email id";
export const contactNumberErrorMessage = "Please enter valid contact number";
export const passwordErrorMessage = "Password is not valid.";
export const newPasswordErrorMessage = "The password must include more than 8 characters with letters (both upper and lower case) numbers or symbols.";
export const newConfirmPasswordErrorMessage = "Password did not matched";
export const passwordIsRequiredErrorMessage = "Please enter password";
export const privacyPolicyErrorMessage = "Please accept our Privacy Policy to start on boarding";
export const tokenErrorMessage = "Please enter a valid token.";
export const inputFieldErrorMessage = "Please enter valid input.";
export const nameErrorMessage = "Please enter valid name.";
export const postCodeErrorMessage = "Please enter valid post code.";
export const addressErrorMessage = "Please enter valid address.";
export const nationalityErrorMessage = "Please enter valid nationality.";
export const lockPeriodMessage = "Please select a valid input.";
export const invalidFileTypeErrorMessage = "Invalid file type.";
export const fileSizeErrorMessage = "The size of file is not more than 1MB";
export const dateIsRequiredErrorMessage = "Please select date";
export const descriptionErrorMessage = "Please enter valid description";
export const linkIsRequiredErrorMessage = "Please enter link";
export const titleIsRequiredErrorMessage = "Please enter title";
export const locationErrorMessage = "Please enter valid location";
export const countryErrorMessage = "Please enter valid country";
export const energyErrorMessage = "Please enter valid energy";
export const minimumInvestmentErrorMessage = "Please enter valid minimum investment";   
export const totalProjectValueValidErrorMessage = "Please enter valid total project value";
export const costPerEfwErrorMessage = "Please enter valid cost per efw";
export const ppaErrorMessage = "Please enter valid PPA";
export const apyErrorMessage = "Please enter valid APY";
export const validInput = "Please enter valid data";
export const loginErrorMessage = "Please enter a valid email or password";
export const votingThresholdErrorMessage = "Please enter valid voting threshold";
export const totalSupplyMessage = "Please enter total supply";
export const targetMessage = "Please enter target value";
export const minYearMessage = "Please select minimum year";
export const maxYearMessage = "Please select maximum year";