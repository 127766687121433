
import { tokenErrorMessage, emailErrorMessage, inputFieldErrorMessage, newConfirmPasswordErrorMessage, newPasswordErrorMessage, passwordErrorMessage, privacyPolicyErrorMessage, nameErrorMessage, postCodeErrorMessage, addressErrorMessage, nationalityErrorMessage, invalidFileTypeErrorMessage, fileSizeErrorMessage, passwordIsRequiredErrorMessage, emailIsRequiredErrorMessage, lockPeriodMessage, dateIsRequiredErrorMessage, descriptionErrorMessage, linkIsRequiredErrorMessage, titleIsRequiredErrorMessage, contactNumberErrorMessage, locationErrorMessage, energyErrorMessage, countryErrorMessage, minimumInvestmentErrorMessage, totalProjectValueValidErrorMessage, costPerEfwErrorMessage, ppaErrorMessage, apyErrorMessage, validInput, votingThresholdErrorMessage, minYearMessage, maxYearMessage, targetMessage, totalSupplyMessage } from "./validationMsg";

  export function isEmailValid(email) {
   
    if(email.length == 0){
      return { isValid: false, message: emailIsRequiredErrorMessage };

    }else{
    if(isValidEmail(email)){
        return { isValid: true, message: '' };
    }else{

        return { isValid: false, message: emailErrorMessage };
    }
  }
  }
  
  export function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
    }


    export function isPasswordValid(password) {
      if(password.length != 0 ){
          return { isValid: true, message: '' };
  
      }else{
          return { isValid: false, message: passwordIsRequiredErrorMessage };
      }
    }

    export function isNewPasswordValid(password) {
      if(password.length == 0){
        return { isValid: false, message: passwordIsRequiredErrorMessage }; 
      }else{
      if(isValidPassword(password)){
          return { isValid: true, message: '' };
  
      }else{
          return { isValid: false, message: newPasswordErrorMessage };
      }
    }
    }
 
    export function isValidPassword(password) {
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d|\W).{9,}$/.test(password);
      }
  

      export function isConfirmPasswordValid(password,confirmPassword) {
        if(confirmPassword.length == 0){
          return { isValid: false, message: passwordIsRequiredErrorMessage }; 
        }else{
          if(password === confirmPassword){
            return { isValid: true, message: '' };    
        }else{
            return { isValid: false, message: newConfirmPasswordErrorMessage };
        }
        }
        
      }


      export function isPrivacyPolicyValid(policy) {
        if(policy){
            return { isValid: true, message: '' };
    
        }else{
            return { isValid: false, message: privacyPolicyErrorMessage };
        }
      }



      export function isSwapFromValid(swapFrom) {
   
        if(swapFrom.length != 0 && swapFrom > 0){
            return { isValid: true, message: '' };
        }else{
    
            return { isValid: false, message: tokenErrorMessage };
        }
      }

      export function isSwapToValid(swapTo) {
   
        if(swapTo.length != 0 && swapTo > 0){
            return { isValid: true, message: '' };
        }else{
    
            return { isValid: false, message: tokenErrorMessage };
        }
      }

      export function isEfwtCountValid(efwt) {
   
        if(efwt.length != 0 && efwt > 0){
            return { isValid: true, message: '' };
        }else{
            return { isValid: false, message: tokenErrorMessage };
        }
      }

      export function isLockPeriodValid(lockPeriod) {
        // console.log(lockPeriod.value)
        if(lockPeriod > 0){
            return { isValid: true, message: '' };
        }else{
    
            return { isValid: false, message: lockPeriodMessage  };
        }
      }  

      export function isMinimumYearValid(minYear) {
        // console.log(minYear.value)
        if(minYear > 0){
            return { isValid: true, message: '' };
        }else{
    
            return { isValid: false, message: minYearMessage  };
        }
      }    

      export function isMaximumYearValid(maxYear) {
        // console.log(maxYear.value)
        if(maxYear > 0){
            return { isValid: true, message: '' };
        }else{
    
            return { isValid: false, message: maxYearMessage  };
        }
      }    

      export function isTargetValid(value) {
        // console.log(lockPeriod.value)
        if(value > 0){
            return { isValid: true, message: '' };
        }else{
    
            return { isValid: false, message: targetMessage  };
        }
      }    

      export function isTotalSupplyValid(value) {
        // console.log(lockPeriod.value)
        if(value > 0){
            return { isValid: true, message: '' };
        }else{
    
            return { isValid: false, message: totalSupplyMessage  };
        }
      }    

      export function isMinimumInvestmentValid(value) {
        // console.log(value.value)
        if(value > 0){
            return { isValid: true, message: '' };
        }else{
    
            return { isValid: false, message: minimumInvestmentErrorMessage  };
        }
      }    

      export function isTotalProjectValueValid(value) {
        // console.log(lockPeriod.value)
        if(value > 0){
            return { isValid: true, message: '' };
        }else{
    
            return { isValid: false, message: totalProjectValueValidErrorMessage  };
        }
      }    

      export function isCostPerEfwValid(value) {
        // console.log(lockPeriod.value)
        if(value > 0){
            return { isValid: true, message: '' };
        }else{
    
            return { isValid: false, message: costPerEfwErrorMessage  };
        }
      }  

      export function isppaValid(value) {
        // console.log(lockPeriod.value)
        if(value > 0){
            return { isValid: true, message: '' };
        }else{
    
            return { isValid: false, message: ppaErrorMessage  };
        }
      }  

      export function isapyValid(value) {
        // console.log(lockPeriod.value)
        if(value > 0){
            return { isValid: true, message: '' };
        }else{
    
            return { isValid: false, message: apyErrorMessage  };
        }
      }  


      export function isInputValid(inputValue) {
        if(inputValue.length != 0){
            return { isValid: true, message: '' };
    
        }else{
            return { isValid: false, message: inputFieldErrorMessage };
        }
      }

      export function isNameValid(name) {
        if(name.length != 0){
            return { isValid: true, message: '' };
    
        }else{
            return { isValid: false, message: nameErrorMessage };
        }
      }

      export function isVotingThresholdValid(value) {
        if(value.length != 0){
            return { isValid: true, message: '' };
        }else{
            return { isValid: false, message: votingThresholdErrorMessage };
        }
      }

      export function isDescriptionValid(name) {
        if(name.length != 0){
            return { isValid: true, message: '' };
    
        }else{
            return { isValid: false, message: descriptionErrorMessage };
        }
      }

      export function isLinkValid(link) {
        if(link.length != 0){
            return { isValid: true, message: '' };
    
        }else{
            return { isValid: false, message: linkIsRequiredErrorMessage };
        }
      }

    export function isTitleValid(title) {
        if(title.length != 0){
            return { isValid: true, message: '' };
    
        }else{
            return { isValid: false, message: titleIsRequiredErrorMessage };
        }
      }


      export function isPostCodeValid(code) {
        if(code.length != 0){
            return { isValid: true, message: '' };
    
        }else{
            return { isValid: false, message: postCodeErrorMessage };
        }
      }

      export function isAddressValid(address) {
        if(address.length != 0){
            return { isValid: true, message: '' };
    
        }else{
            return { isValid: false, message: addressErrorMessage };
        }
      }     

      export function isNationalityValid(nationality) {
        if(nationality.length != 0){
            return { isValid: true, message: '' };
        }else{
            return { isValid: false, message: nationalityErrorMessage };
        }
      }

      export function isDateValid(date)
       { if (date != null && date != "") {
         return {
           isValid: true, message: '' };
           } else {
             return { 
              isValid: false, message: dateIsRequiredErrorMessage 
            }; 
          }
         }

        export function isContactNumberValid(contactNumber) {
          if (contactNumber.length != 0 && /^\d+$/.test(contactNumber)) {
            return { isValid: true, message: '' };
          } else {
            return { isValid: false, message: contactNumberErrorMessage };
          }
        }


        export function isLocationValid(location) {
          if (location.length != 0) {
            return { isValid: true, message: '' };
          } else {
            return { isValid: false, message: locationErrorMessage };
          }
        }

        export function isTokenNameValid(tokenName) {
          if (tokenName.length != 0) {
            return { isValid: true, message: '' };
          } else {
            return { isValid: false, message: tokenErrorMessage };
          }
        }

        export function isSymbolValid(symbol) {
          if (symbol.length != 0) {
            return { isValid: true, message: '' };
          } else {
            return { isValid: false, message: validInput };
          }
        }


        export function isCountryValid(country) { 
          if (country.length != 0) {
             return { isValid: true, message: '' }; 
            } else { 
              return { isValid: false, message: countryErrorMessage };
             } 
            }

        export function isEnergyValid(energy) {
           if (energy.length != 0) { 
            return { isValid: true, message: '' }; 
          } else { 
            return { isValid: false, message: energyErrorMessage }; 
          }
         }



      export function validateFile(file) {
        // Implement file validation logic here
        // For example, you can check the file type, size, or other criteria
        const allowedTypes = ['image/jpeg', 'image/png'];
        const maxSize = 1024 * 1024; // 1MB
      
        if (!allowedTypes.includes(file.type)) {
          return { isValid: false, message: invalidFileTypeErrorMessage };
          // return false; // Invalid file type
        }
        if (file.size > maxSize) {
          return { isValid: false, message: fileSizeErrorMessage };
        }
        return { isValid: true, message: '' };
       // File is valid
      };
      
      export function isPdfFileValid(file) {
        const allowedTypes = ['application/pdf'];
        const maxSize = 1024 * 1024 * 10; // 10MB

        if (!allowedTypes.includes(file.type)) {
          return { isValid: false, message: 'Invalid file type. Only PDF files are allowed.' };
        }
        if (file.size > maxSize) {
          return { isValid: false, message: 'File size exceeds the maximum limit. Maximum file size allowed is 10MB.' };
        }
        return { isValid: true, message: '' };
      }