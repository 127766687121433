// /* eslint-disable @typescript-eslint/no-unused-vars */
// // src/pages/LandingPage.tsx
// import React from "react";
// import DefaultLayout from "@/wireframes/layouts/default";
// import Header from "@/components/header/Header";
// import Footer from "@/components/header/Footer";
// import AboutUsSection from "@/page-sections/landing/about-us-section";
// import HeaderSection from "@/page-sections/landing/header-section";
// import DownloadSection from "@/page-sections/landing/download-section ";
// import FAQSection from "@/page-sections/landing/faq-section";
// import HowItWorksSection from "@/page-sections/landing/how-it-works-section";
// import TeamsSection from "@/page-sections/landing/teams-section";
// import LearnWithUsSection from "@/page-sections/landing/learn-with-us-section";
// import RoadmapSection from "@/page-sections/landing/roadmap-section";
// import WhyVaniCarousel from "@/page-sections/landing/why-vani-carousel-section";
// import ContactUsSection from "@/page-sections/landing/contact-us-section";
// import WaitListSection from "@/page-sections/landing/wait-list-section";
// import IframeSection from "@/page-sections/landing/iframe-section";
// import VoteSection from "./landing/vote-section";
// const LandingPage: React.FC = () => {
//   return (
//     <>
//       <DefaultLayout header={<Header />} footer={<Footer />}>
//         <div className="section-colors">
//           <HeaderSection />
//         </div>
//         <div className="section-colors">
//           <AboutUsSection />
//         </div>
     
//         <div className="section-colors">
//           {/* <WhyVaniSection /> */}
//           <WhyVaniCarousel />
//         </div>
//         <div className="section-colors">
//           <LearnWithUsSection />
//         </div>
        
       
//         {/* <div className="section-colors">
//           <DownloadSection />
//         </div> */}
//         <div className="section-colors">
//           <HowItWorksSection />
//         </div>

//         <div className="section-colors">
//           <WaitListSection />
//         </div>

//         <div className="section-colors">
//           <RoadmapSection />
//         </div>

//         <div className="section-colors">
//           <TeamsSection />
//         </div>

//         <div id="vote" className="section-colors">
//           <VoteSection />
//         </div>

//         <div className="section-colors">
//           <FAQSection />
//         </div>
//         <div className="section-colors">
//           <ContactUsSection />
//         </div>
//         <div className="section-colors">
//           <IframeSection />
//         </div>
//       </DefaultLayout>
//     </>
//   );
// };

// export default LandingPage;


import React from "react";
import DefaultLayout from "@/wireframes/layouts/default";
import Header from "@/components/header/Header";
import Footer from "@/components/header/Footer";
import AboutUsSection from "@/page-sections/landing/about-us-section";
import HeaderSection from "@/page-sections/landing/header-section";
// import DownloadSection from "@/page-sections/landing/download-section"; // Remove this if not in use
import FAQSection from "@/page-sections/landing/faq-section";
import HowItWorksSection from "@/page-sections/landing/how-it-works-section";
import TeamsSection from "@/page-sections/landing/teams-section";
import LearnWithUsSection from "@/page-sections/landing/learn-with-us-section";
import RoadmapSection from "@/page-sections/landing/roadmap-section";
import WhyVaniCarousel from "@/page-sections/landing/why-vani-carousel-section";
import ContactUsSection from "@/page-sections/landing/contact-us-section";
import WaitListSection from "@/page-sections/landing/wait-list-section";
import IframeSection from "@/page-sections/landing/iframe-section";
import VoteSection from "./landing/vote-section";

// Import necessary hooks
import { useEffect } from "react";

const LandingPage: React.FC = () => {
  // Scroll to the vote section
  // const handleVoteClick = () => {
  //   const voteSection = document.getElementById("vote");
  //   if (voteSection) {
  //     voteSection.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  return (
    <>
      <DefaultLayout header={<Header />} footer={<Footer />}>
        <div className="section-colors">
          <HeaderSection />
        </div>
        <div className="section-colors">
          <AboutUsSection />
        </div>
        <div className="section-colors">
          {/* <WhyVaniSection /> */}
          <WhyVaniCarousel />
        </div>
        <div className="section-colors">
          <LearnWithUsSection />
        </div>
        <div className="section-colors">
          <HowItWorksSection />
        </div>
        <div className="section-colors">
          <WaitListSection />
        </div>
        <div className="section-colors">
          <RoadmapSection />
        </div>
        <div className="section-colors">
          <TeamsSection />
        </div>
        <div id="vote" className="section-colors">
          <VoteSection />
        </div>
        <div className="section-colors">
          <FAQSection />
        </div>
        <div className="section-colors">
          <ContactUsSection />
        </div>
        <div className="section-colors">
          <IframeSection />
        </div>
      </DefaultLayout>

      {/* Floating Vote Button */}
      <a  href="https://ai.google.dev/competition/projects/vani"target="_blank" >
      <button className="floating-vote-button">
        Vote Now
      </button>
      </a>
    </>
  );
};

export default LandingPage;