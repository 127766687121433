import React from "react";
import DefaultLayout from "@/wireframes/layouts/default";
import Header from "@/components/header/Header";
import Footer from "@/components/header/Footer";
import Components from "@/page-sections/components/components";

const ComponentsPage: React.FC = () => {
  return (
    <>
      <DefaultLayout header={<Header />} footer={<Footer />}>
        <div className="section-colors">
          <Components />
        </div>
      </DefaultLayout>
    </>
  );
};

export default ComponentsPage;
